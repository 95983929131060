<template>
  <base-section id="products-category">
    <v-container :style="$vuetify.breakpoint.lgAndUp?'margin-top:-100px':'margin-top:-50px'" v-if="this.products.id !== 'asuransi-kesehatan'">
        <v-row justify="center" no-gutters>
          <v-col lg="12" sm="12">
            <v-img
                  :src="require(`@/assets/${products.top}`)"
                  class="white--text align-start rounded-lg"
                  lazy-loading
                >
                </v-img>
        </v-col>
        <v-col lg="4" sm="12">
            <v-img
                  :src="require(`@/assets/${products.left1}`)"
                  class="white--text align-start rounded-lg cursor"
                  :style="$vuetify.breakpoint.lgAndUp?'margin-left:-5px':''"
                  lazy-loading
                  @click="OpenDialog(products.left1_title,products.left1,products.left1_desc)"
                >
                </v-img>
        </v-col>
         <v-col lg="4" sm="12">
            <v-img
                  :src="require(`@/assets/${products.middle}`)"
                  class="white--text align-start rounded-lg mt-n5 ml-n1 cursor"
                  lazy-loading
                  @click="OpenDialog(products.middle_title,products.middle,products.middle_desc)"
                >
                </v-img>
        </v-col>
        <v-col lg="4" sm="12">
            <v-img
                  :src="require(`@/assets/${products.right1}`)"
                  class="white--text align-self-start rounded-lg cursor"
                  lazy-loading
                  @click="OpenDialog(products.right1_title,products.right1,products.right1_desc)"
                >
                </v-img>
        </v-col>
        <v-col lg="4" sm="12">
            <v-img
                  :src="require(`@/assets/${products.left2}`)"
                  class="white--text align-start rounded-lg cursor"
                  :style="$vuetify.breakpoint.lgAndUp?'margin-top:-230px;margin-left:-10px':''"
                  lazy-loading
                  @click="OpenDialog(products.left2_title,products.left2,products.left2_desc)"
                >
                </v-img>
        </v-col>
        <v-col lg="4" sm="12">&nbsp;</v-col>

        <v-col lg="4" sm="12">
            <v-img
                  :src="require(`@/assets/${products.right2}`)"
                  class="white--text align-start rounded-lg cursor"
                  :style="$vuetify.breakpoint.lgAndUp?'margin-top:-230px;':''"
                  lazy-loading
                  @click="OpenDialog(products.right2_title,products.right2,products.right2_desc)"
                >
                </v-img>
        </v-col>
        </v-row>
        <v-dialog
      v-model="zoom.value"
      width="800"
    >
    <v-card>
        <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                text
                @click="zoom.value = false"
              >
                TUTUP
              </v-btn>
            </v-card-actions>
        <v-card-title class="body-2 grey lighten-3 primary--text">
          PRODUK
        </v-card-title>
        <v-card-text>
          <v-img
            :src="require(`@/assets/${zoom.src}`)"
            height="100%"
            cover
          ></v-img>
            <v-layout row>
              <v-col lg="12" sm="12">
                <div v-html="zoom.desc"></div>
              </v-col>
            </v-layout>
        </v-card-text>
    </v-card>
    </v-dialog>
      </v-container>
    <v-container v-else>
    <base-section-heading :title="data.title"/>
      <v-row>
        <v-col
          cols="12"
          md="12"
        >
          <template>
          <v-card flat>
            <div>
              <div v-html="data.detail"></div>
            </div>
          </v-card>
        </template>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionProductsCategory',
    data: () => ({
        data: {
          title: '',
          detail: '',
        },
        products: {
          id: '',
          title: '',
          top: 'penjaminan-atas.jpg',
          left1: 'penjaminan-atas.jpg',
          left1_title: '',
          left1_desc: '',
          left2: 'penjaminan-atas.jpg',
          left2_title: '',
          left2_desc: '',
          middle: 'penjaminan-atas.jpg',
          middle_title: '',
          middle_desc: '',
          right1: 'penjaminan-atas.jpg',
          right1_title: '',
          right1_desc: '',
          right2: 'penjaminan-atas.jpg',
          right2_title: '',
          right2_desc: '',
        },
        zoom: {
          value: false,
          title: '',
          src: 'penjaminan-atas.jpg'
        }
    }),
    watch: {
        "$route.params.url"(val) {
          console.log(val)
          this.List();
        },
    },
    created () {
      this.List()
    },
    methods: {
      List () {
        let paramsid = this.$route.params.url
        if (paramsid === 'asuransi-penjaminan') {
            this.products.id = paramsid
            this.products.title  = 'Asuransi Keuangan (Financial Insurance) dan Penjaminan'
            this.products.top  = 'penjaminan-atas.jpg'
            this.products.left1  = 'penjaminan-kontra-bank-garansi.png'
            this.products.left1_title  = 'Kontra Bank Garansi'
            this.products.left1_desc  = `<p>Memberikan jaminan dalam bentuk kontra garansi atas fasilitas bank garansi yang diterbitkan oleh Bank kepada Principal apabila Principal mengalami wanprestasi dalam pelaksanaan pekerjaannya.</p>
                                        <p>Salah satu manfaat <strong>Kontra Bank Garansi</strong> adalah&middot; memperoleh jaminan dalam menerbitkan Bank Garansi tanpa memerlukan cash collateral 100% seperti sebagaimana yang dipersyaratkan oleh Bank. Sehingga akan membantu Principal dalam mengelola cash flow perusahaan.&middot;</p>
                                        <p>Adapun jenis jaminannya meliputi :</p>
                                        <p><strong>1) Jaminan Penawaran (Bid Bond)</strong></p>
                                        <p><strong>2) Jaminan Pelaksanaan (Maintenance Bond)</strong></p>
                                        <p><strong>3) Jaminan Uang Muka (Advance Payment Bond)</strong></p>
                                        <p><strong>4) Jaminan Pemeliharaan (Maintenance Bond)</strong></p>
                                        <p><strong>5) Jaminan SP2D</p>`
            this.products.middle  = 'penjaminan-surety.png'
            this.products.middle_title  = 'Surety Bond'
            this.products.middle_desc  = `<p>Memberikan jaminan kepada Pemilik Pekerjaan (Obligee) terhadap wanprestasi yang timbul akibat tidak dipenuhinya kewajiban oleh Pelaksana Pekerjaan (Principal) atas suatu pekerjaan (konstruksi/non konstruksi) dalam jangka waktu yang telah ditentukan di dalam kontrak.</p>
                                          <p><strong>1) Jaminan Penawaran (Bid Bond)</strong></p>
                                          <p>Jaminan yang diterbitkan oleh <em>Surety Company</em> untuk menjamin <em>Obligee</em> bahwa <em>Principal</em> pemegang <em>Bid Bond</em> telah memenuhi persyaratan yang telah ditentukan oleh <em>Obligee</em> untuk mengikuti pelelangan tersebut dan apabila <em>Principal </em>memenangkan pelelangan maka akan sanggup untuk menutup Kontrak Pelaksanaan Pekerjaan dengan <em>Obligee</em>. Apabila tidak maka <em>Surety Company</em> akan membayar kerugian kepada <em>Obligee </em>sebesar selisih antara penawaran<em> Principal </em>yang terendah dengan <em>Principal </em>terendah berikutnya maksimum sebesar nilai jaminan.</p>
                                          <p><strong>2) Jaminan Pelaksanaan (Maintenance Bond)</strong></p>
                                          <p>Jaminan yang diterbitkan oleh Surety Company untuk menjamin Obligee bahwa Principal akan dapat menyelesaikan pekerjaan yang diberikan oleh Obligee sesuai dengan ketentuan-ketentuan yang diperjanjikan dalam kontrak pekerjaan. Apabila Principal tidak melaksanakan kewajibannya sesuai dengan kontrak maka Surety Company akan memberikan ganti rugi kepada obligee maksimum sebesar nilai jaminan. Besarnya nilai Jaminan (Penal Sum) Pelaksanaan adalah prosentase tertentu dari nilai kontrak proyek itu sendiri yaitu antara 5% s/d 10% dari nilai proyek.</p>
                                          <p><strong>3) Jaminan Uang Muka (Advance Payment Bond)</strong></p>
                                          <p>Jaminan Pembayaran Uang Muka atau Advance Payment Bond yang diterbitkan oleh Surety Company untuk menjamin Obligee bahwa Principal akan sanggup mengembalikan uang muka yang telah diterimanya dari Obligee sesuai dengan ketentuan-ketentuan yang diperjanjikan dalam kontrak, dengan maksud untuk mempelancar pembiayaan proyek.</p>
                                          <p><strong>4) Jaminan Pemeliharaan (Maintenance Bond)</strong></p>
                                          <p>Jaminan Pemeliharaan atau yang disebut juga Maintenance Bond diterbitkan oleh Surety Company untuk menjamin Obligee bahwa principal akan sanggup untuk memperbaiki kerusakan-kerusakan pekerjaan setelah pelaksanaan pekerjaan selesai sesuai dengan yang diperjanjikan dalam kontrak.</p>
                                          <p><strong>5) Jaminan SP2D</strong></p>
                                          <p>Jaminan Surat Perintah Pencairan Dana (SP2D) adalah Penjaminan Bank Garansi / Surety Bond yang diterbitkan oleh Penjamin kepada Penerima Jaminan untuk menjamin Terjamin/Principal menyelesaikan pekerjaan yang telah dilakukan pembayaran sebelum pekerjaan selesai</p>`
            this.products.right1  = 'penjaminan-custom-bond.png'
            this.products.right1_title  = 'Custom Bond'
            this.products.right1_desc  = `<p>Memberikan jaminan kepada Direktorat Jenderal (Ditjen) Bea dan Cukai atas risiko tidak diselesaikan kewajiban oleh Eksportir/Importir atas fasilitas kepabeanan, fasilitas penangguhan/pembebasan bea masuk barang impor dan pungutan negara lainnya</p>
                                          <p>&nbsp;</p>
                                          <p>Pengusaha yang memproduksi barang-barang/hasil industri yang akan diekspor (produsen eksportir) dapat diberikan pembebasan bea masuk, bea masuk tambahan (surcharge) dan Pajak Pertambahan Nilai (PPN) atas barang-barang yang diimpornya untuk menghasilkan barang produksi. Salah satu pernyataan untuk mendapatkan pembebasan bea masuk, bea masuk tambahan dan PPN adalah bahwa pengusaha harus memiliki Custom Bond. Bilamana dalam jangka waktu tertentu pengusaha yang bersangkutan, lalai/tidak mengekspor barang hasil produksinya, maka Custom Bond tersebut akan dicairkan oleh Pemerintah. Fungsi Custom Bond disini adalah menjamin pemerintah bila pengusaha lalai/tidak mengekspor barang-barang produksinya.</p>
                                          <p><strong>1) KITE (Kemudahan Impor Tujuan Ekspor)</strong></p>
                                          <p><strong>2) KABER (Kawasan Berikat)</strong></p>
                                          <p><strong>3) PPJK (Perusahaan Pengurusan Jasa Kepabeanan)</strong></p>
                                          <p><strong>4) OB-23 (Impor Sementara)</strong></p>
                                          <p><strong>5) TPS (Tempat Penimbunan Sementara)</strong></p>`
            this.products.left2  = 'penjaminan-asuransi-kredit.png'
            this.products.left2_title  = 'Asuransi Kredit'
            this.products.left2_desc  = `<p>Memberikan perlindungan finansial kepada tertanggung (Bank/IKNB/Lembaga Non Keuangan) atas kerugian yang diderita akibat gagal bayarnya kredit yang diajukan oleh debitur tertanggung yang disebabkan oleh debitur tertanggung tidak melaksanakan kewajiban sesuai Perjanjian Kredit tanpa memperhatikan apapun penyebabnya sehingga mengakibatkan fasilitas kredit menjadi bermasalah.</p>`
            this.products.right2  = 'penjaminan-kredit-perdagangan.png'
            this.products.right2_title  = 'Kredit Perdagangan'
            this.products.right2_desc  = `<p>Memberikan pertanggungan kepada pabrikan/distributor karena kemungkinan gagalnya pembayaran sejumlah piutang kredit perdagangan oleh buyer sesuai dengan kontrak perdagangan antara pabrikan/distributor dengan buyer akibat pailit atau gagal bayar dalam kurun waktu tertentu</p>`
            this.data.title = 'Asuransi Keuangan (Financial Insurance) dan Penjaminan'
            this.data.detail = `<p>&nbsp;</p>
                            <ul style="list-style: none" class="ml-n6">
                            <li><b>B. &nbsp; Kontra Bank Garansi&nbsp;</b></li>
                            </ul>
                            <p>Memberikan jaminan dalam bentuk kontra garansi atas fasilitas bank garansi yang diterbitkan oleh Bank kepada Principal apabila Principal mengalami wanprestasi dalam pelaksanaan pekerjaannya.</p>
                            <p>Salah satu manfaat <strong>Kontra Bank Garansi</strong> adalah&middot; memperoleh jaminan dalam menerbitkan Bank Garansi tanpa memerlukan cash collateral 100% seperti sebagaimana yang dipersyaratkan oleh Bank. Sehingga akan membantu Principal dalam mengelola cash flow perusahaan.&middot;</p>
                            <p>Adapun jenis jaminannya meliputi :</p>
                            <p><strong>1) Jaminan Penawaran (Bid Bond)</strong></p>
                            <p><strong>2) Jaminan Pelaksanaan (Maintenance Bond)</strong></p>
                            <p><strong>3) Jaminan Uang Muka (Advance Payment Bond)</strong></p>
                            <p><strong>4) Jaminan Pemeliharaan (Maintenance Bond)</strong></p>
                            <p><strong>5) Jaminan SP2D&nbsp;</p>`
        } else if(paramsid === 'asuransi-umum') {
          this.products.id = paramsid
          this.products.title  = 'Asuransi Keuangan (Financial Insurance) dan Penjaminan'
          this.products.top  = 'asuransi-umum-banner.jpg'
          this.products.left1  = 'asuransi-kendaraan-bermotor.png'
          this.products.left1_title  = 'Asuransi Kendaraan Bermotor'
          this.products.left1_desc  = `<p>merupakan produk asuransi yang memberikan perlindungan atas kerugian / kerusakan / kehilangan pada kendaraan bermotor (baik roda dua maupun roda empat) akibat dari risiko yang dijamin dalam Polis Standar Asuransi Kendaraan Bermotor Indonesia (PSAKBI).</p>
                                      <p>Jaminan yang dapat diberikan antara lain :</p>
                                      <p><strong>1) Jaminan Comprehensive / Gabungan</strong></p>
                                      <p><strong>2) Jaminan Total Loss</strong></p>
                                      <p><strong>3) TJH Pihak Ketiga</strong></p>
                                      <p><strong>4) Perluasan jaminan lainnya</strong></p>`
          this.products.middle  = 'asuransi-harta-benda.png'
          this.products.middle_title  = 'Asuransi Harta Benda'
          this.products.middle_desc  = `<p>Produk asuransi yang memberikan perlindungan atas kerusakan yang terjadi secara tiba-tiba dan tidak terduga sebagai akibat dari peristiwa / risiko yang dijamin dalam polis, antara lain : kebakaran, sambaran petir, ledakan, tertimpa pesawat, asap (FLEXAS), angina topan, badai, banjir, tanah longsor, gempa bumi, pemogokan, huru hara, kerusuhan, perbuatan jahat orang lain dan risiko lainnya.</p>
                                        <p>Jenis-jenis polis pada Asuransi Harta Benda :</p>
                                        <p><strong>1) Asuransi Kebakaran (Fire &amp; Allied Perils Insurance)</strong></p>
                                        <p><strong>2) Property All Risks (PAR) Insurance</strong></p>
                                        <p><strong>3) Industrial All Risks (IAR) Insurance</strong></p>
                                        <p><strong>4) Asuransi Gempa Bumi (PSAGBI)</strong></p>`
          this.products.right1  = 'asuransi-rekayasa.png'
          this.products.right1_title  = 'Asuransi Rekayasa'
          this.products.right1_desc  = `<p>Asuransi Rekayasa adalah asuransi yang menjamin&nbsp;pemilik dan atau kontraktor atas proyek konstruksi serta instalasinya. Selain itu, Asuransi Rekayasa juga dapat menjaminkan alat-alat berat, mesin-mesin produksi, dan peralatan elektronik,&nbsp;dengan rincian sebagai berikut:&nbsp;</p>
                                        <p><strong>1) Asuransi Konstruksi / Contractor&rsquo;s All Risks (CAR) Insurance</strong></p>
                                        <p>Diperuntukan untuk proyek pembangunan gedung atau proyek teknik sipil, sepanjang risiko tidak dikecualikan dalam polis. Asuransi ini menjamin: kebakaran, petir, ledakan, kejatuhan pesawat terbang, asap, banjir, genangan, hujan, salju, badai, tsunami, angin topan, gempa bumi, pergerakan tanah, tanah batuan longsor, pencurian, pengerjaan yang buruk, kurangnya keterampilan, kelalaian, tindakan jahat atau kesalahan manusia, dan tanggung jawab hukum pihak ketiga&nbsp;</p>
                                        <p><strong>2) Asuransi Pemasangan Mesin / Erection All Risks (EAR) Insurance</strong></p>
                                        <p>Diperuntukan untuk proyek pemasangan mesin dan peralatan pendukungnya. Asuransi ini menjamin risiko-risiko yang sama dengan Asuransi Konstruksi.</p>
                                        <p><strong>3) Asuransi Rekayasa (Engineering Insurance)</strong></p>
                                        <p>Diperuntukan untuk menjamin ganti rugi terhadap alat berat tertanggung akibat kerusakan atau kerugian yang terjadi karena kejadian yang disebabkan oleh risiko yang dijamin dalam polis.Ada 2 jenis jaminan asuransi alat berat:A. Semua RisikoMenjamin kerugian atau kerusakan pada alat berat, baik kerugian sebagian atau kerugian total, selain dari risiko yang dikecualikan dalam polis.B. Kerusakan Total SajaMenjamin kerusakan total yang terjadi pada alat berat sama dengan atau diatas 75% dari harga pasar pada saat kejadian.Menjamin kehilangan akibat pencurian.&nbsp;</p>
                                        <p><strong>4) Asuransi Kerusakan Mesin / Machinery Breakdown (MB) Insurance</strong></p>
                                        <p>Diperuntukan untuk mesin- mesin atau peralatan mekanis, motor- motor dan peralatan lainnya. Asuransi ini menjamin&nbsp;suatu kerugian atau kerusakan fisik yang tidak terduga dan tiba-tiba oleh sebab-sebab seperti cacat dalam pencetakan dan material, salah desain, salah pengerjaan atau pemasangan, pengerjaan buruk, kurangnya keterampilan, kecerobohan, kekurangan air dalam boiler, ledakan fisik, koyak akibat gaya sentrifugal, arus pendek, badai, atau sebab lain yang tidak dikecualikan oleh polis, sehingga memerlukan perbaikan atau penggantian.</p>
                                        <p><strong>5) Asuransi Peralatan Elektronik / Electronic Equipment Insurance (EEI)</strong></p>
                                        <p>Diperuntukan untuk pemilik maupun penyewa dari alat-alat elektronik. Asuransi ini menjamin kerusakan/kerugian barang atau peralatan elektronik yang diasuransikan baik pada saat kerja, istirahat, atau sedang dibongkar untuk keperluan pembersihan, perawatan menyeluruh, saat dipindahkan dalam lokasi, selama dalam rangkaian pengoperasiannya atau selama pemasangan kembali. Tetapi dalam hal apapun hanya setelah uji coba berhasil, kecuali risiko yang masuk dalam pengecualian polis.</p>`
          this.products.left2  = 'asuransi-pengiriman-barang.png'
          this.products.left2_title  = 'Asuransi Pengiriman Barang'
          this.products.left2_desc  = `<p>Merupakan salah satu jenis asuransi yang memberikan perlindungan atas kerusakan / kerugian / kehilangan muatan / cargo terhadap risiko-risiko yang dijamin dalam polis, yang berlaku sejak saat barang bergerak meninggalkan Gudang atau tempat penyimpanan yang disebutkan dalam polis sebagai awal dimulainya perjalanan, dan berlaku selama dalam perjalanan yang wajar hingga barang diterima di Gudang tujuan yang disebutkan dalam polis.</p>
          <p>Jenis polis / klausula Asuransi Pengiriman Barang / Marine Cargo Insurance</p>
          <p><strong>1) Institute Cargo Clauses A 1.1.82 (All Risks)</strong></p>
          <p><strong>2) Institute Cargo Clause B 1.1.82</strong></p>
          <p><strong>3) Institute Cargo Clause C 1.1.82</strong></p>
          <p><strong>4) Polis Standar Asuransi Pengangkutan Barang Indonesia (PSAPBI) Jaminan I (All Risks)/Jaminan II/Jaminan III</strong></p>
          <p>Untuk seluruh moda transportasi dan seluruh wilayah di dunia (kecuali daerah konflik / perang)</p>`
          this.products.right2  = 'asuransi-rangka-kapal.png'
          this.products.right2_title  = 'Asuransi Rangka Kapal'
          this.products.right2_desc  = `<p>Merupakan produk asuransi yang memberikan perlindungan atas kerusakan atau kerugian terhadap kapal, mesin dan perlengkapannya dari bahaya laut dan risiko pelayaran (navigasi)</p>
          <p>Jenis polis / klausula Asuransi Rangka Kapal / Marine Hull Insurance</p>
          <p><strong>1) Institute Time Clauses Hulls 1.10.83 (Full Terms)</strong></p>
          <p><strong>2) Institute Time Clauses Hulls &ndash; Total Loss, General Average and &frac34; Collision Liability 1.10.83 CL 284 (Including Salvage, Salvage Charges and Sue &amp; Labor)</strong></p>
          <p><strong>3) Institute Time Clauses Hulls &ndash; Total Loss Only 1.10.83 CL 289 (Including Salvage, Salvage Charges and Sue &amp; Labor)</strong></p>
          <p><strong>4) Builder&rsquo;s Risk Insurance</strong></p>`
          this.data.title  = 'Asuransi Umum'
          this.data.detail = `<ul style="list-style: none" class="ml-n6">
          <li><b>A. &nbsp; Asuransi Harta Benda (Property Insurance)&nbsp;</b></li>
          </ul>
          <p></p>
          
          <p>&nbsp;</p>
          <ul style="list-style: none" class="ml-n6">
          <li><b>B. &nbsp; Asuransi Rekayasa (Engineering Insurance)&nbsp;</b></li>
          </ul>
          <p></p>
          
          <p>&nbsp;</p>
          <ul style="list-style: none" class="ml-n6">
          <li><b>C. &nbsp; Asuransi Pengiriman Barang (Marine Cargo Insurance) &nbsp;</b></li>
          </ul>
          <p></p>
          
          <p>&nbsp;</p>
          <ul style="list-style: none" class="ml-n6">
          <li><b>D. &nbsp; Asuransi Rangka Kapal (Marine Hull Insurance) &nbsp;</b></li>
          </ul>
          <p></p>
          
          <p>&nbsp;</p>
          <ul style="list-style: none" class="ml-n6">
          <li><b>E. &nbsp; Asuransi Kendaraan Bermotor (Motor Vehicle Insurance) &nbsp;</b></li>
          </ul>
          <p></p>
          
          <p>&nbsp;</p>
          <ul style="list-style: none" class="ml-n6">
          <li><b>F. &nbsp; Asuransi Tanggung Gugat (Liability Insurance) &nbsp;</b></li>
          </ul>
          <p></p>
          <p>merupakan salah satu jenis asuransi yang memberikan perlindungan terhadap kerugian yang timbul dari tuntutan / klaim pihak ketiga yang menderita kerugian, baik cidera badan dan/atau kerusakan harta benda, yang diakibatkan oleh aktifitas Tertanggung.</p>
          <p>Jenis &ndash; jenis asuransi tanggung gugat antara lain :</p>
          <p>&nbsp;1) &nbsp; Liability Insurance</p>
          <p>2) &nbsp; Comprehensive General Liability (CGL) Insurance</p>
          <p>3) &nbsp; Employer&rsquo;s Liability Insurance</p>
          <p>4) &nbsp; Automobile Liability Insurance</p>
          <p>5) &nbsp; Workmen&rsquo;s Compensation Act (WCA) Insurance</p>
          <p>&nbsp;</p>
          <ul style="list-style: none" class="ml-n6">
          <li><b>G. &nbsp; Asuransi Uang (Money Insurance) &nbsp;</b></li>
          </ul>
          <p></p>
          <p>Asuransi yang memberikan ganti rugi atas risiko kehilangan atau kerusakan uang maupun alat tukar lainnya baik dalam perjalanan maupun dalam tempat penyimpanan.</p>
          <p>Jenis-jenis asuransi uang :</p>
          <p>1) &nbsp; &nbsp; Cash In Safe (CIS) Insurance</p>
          <p>2) &nbsp; &nbsp; Cash In Cashier&rsquo;s Box (CICB) Insurance</p>
          <p>3) &nbsp; &nbsp; Cash In Transit (CIT) Insurance</p>
          <p>4) &nbsp; &nbsp; Fidelity Guarantee Insurance</p>
          <p>&nbsp;</p>
          <ul style="list-style: none" class="ml-n6">
          <li><b>H. &nbsp; Asuransi Kecelakaan Diri (Personal Accident Insurance) &nbsp;</b></li>
          </ul>
          <p></p>
          <p>Merupakan salah satu jenis asuransi yang memberikan perlindungan atas risiko kematian, cacat tetap, biaya perawatan dan atau pengobatan yang secara langsung disebabkan oleh suatu kecelakaan. Yang dimaksud dengan kecelakaan yaitu suatu kejadian atau peristiwa yang mengandung unsur kekerasa, baik bersifat fisik maupun kimia, yang datangnya secara tiba-tiba, termasuk juga kecelakaan yaitu yang disebabkan karena keracunan makanan, uap dan gas, jatuh ke dalam air atau tenggelam.</p>
          <p>&nbsp;</p>
          <ul style="list-style: none" class="ml-n6">
          <li><b>I. &nbsp; Asuransi Perjalanan (Travel Insurance) &nbsp;</b></li>
          </ul>
          <p></p>
          <p>adalah asuransi yang memberikan perlindungan terhadap segala risiko yang mungkin terjadi Ketika seseorang melakukan perjalanan, baik di dalam negeri maupun di luar negeri.</p>`
        } else if(paramsid === 'asuransi-kesehatan') {
          this.products.id = paramsid
          this.data.title  = 'Asuransi Kesehatan (Health Insurance)'
          this.data.detail = `<p>Adalah asuransi yang memberikan perlindungan finansial yang menjamin pihak Tertanggung terhadap biaya pengobatan dan perawatan medis, baik secara individu, keluarga maupun kelompok.</p>`
        }
      },
      OpenDialog (title, src, desc) {
        this.zoom.value = true
        this.zoom.title = title
        this.zoom.src = src
        this.zoom.desc = desc
      }
    }
  }
</script>